import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { AppV2 } from "./app/app";
import { $secondary } from "./assets/colors";

import "react-vis/dist/style.css";
import "./antd-overrides.less";

import { API_URL } from "./api/constants";
import { configureStore } from "./state/store";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { reportAccessibility } from "./app/utils/axecore.utils";

const IS_LIVE =
  window.ENVIRONMENT === "PROD" ||
  window.ENVIRONMENT === "STAGING" ||
  window.ENVIRONMENT === "REVIEW";

const SENTRY_DSN =
  "https://34a3b7454c1c4e758a8933f16e185762@o540269.ingest.sentry.io/5801547";

Sentry.init({
  // debug: !IS_LIVE,
  debug: false,
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", API_URL as string],
      shouldCreateSpanForRequest: (url) => true,
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Integrations.Breadcrumbs({ console: IS_LIVE }),
    new Sentry.Replay(),
  ],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.5,
  environment: window.ENVIRONMENT?.toLowerCase() || "dev",
  release: window.RELEASE || undefined,

  tracesSampler: (samplingContext) => {
    const txName = samplingContext.transactionContext.name;

    if (
      txName.match(
        /\/api\/query\/mbc\/(episodes_of_care|scores_by_client)\//,
      ) ||
      txName.match(/\/api\/mbc\/2\/get_survey_responses_for_event\/.+/)
    ) {
      // high volume simple MBC queries
      return 0.05;
    }

    if (txName.match(/HasuraGraphQL/)) {
      // data is duplicative with our log based metric
      return 0.05;
    }

    if (txName.match(/logged_in_user/)) {
      return 0.01;
    }

    if (txName.match(/\api\/(clinicianSchedules|dxcodes)/)) {
      return 0.05;
    }

    return 0.1;
  },

  beforeSend: (event, hint) => {
    // Supress uncaught Axios Exceptions
    // These are still captured as messages in the api interceptor.
    if (
      hint?.originalException &&
      typeof hint?.originalException === "object" &&
      "isAxiosError" in hint?.originalException
    ) {
      return null;
    }

    // Dont send Sentry events in DEV
    if (!IS_LIVE) {
      console.log("Sending to Sentry: ", event, hint);
      return null;
    }

    return event;
  },

  beforeBreadcrumb: (breadcrumb) => {
    return breadcrumb;
  },
});

export const store = configureStore();

const GlobalStyle = createGlobalStyle`
  .ant-checkbox-group-item {
    margin-bottom: 0.5em !important;
  }

  .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    background-color: ${$secondary} !important;
  }

  .ant-calendar-week-number .ant-calendar-body tr:hover {
    background-color: ${$secondary} !important;
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <AppV2 />
  </Provider>,
  document.getElementById("root") as HTMLElement,
);

// If an error shows up, please do not ignore it
// There are some legacy errors that will require significant work
// They are tagged with a11y and SHOULD be noted here:
// https://docs.google.com/spreadsheets/d/1i2pGU41RMvWPdybyoFjVR3tXHOXb5MKCf4bpIjIToG0/edit#gid=1836394171
// reportAccessibility(React);
