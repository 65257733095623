import React from "react";
import * as colors from "../../assets/colors";
import { ExtendedMatch, BasicMatch, MatchClientStatus } from "../../api/types";
import moment from "moment";

export const parseClientStatus = (
  match: BasicMatch | ExtendedMatch,
): MatchClientStatus =>
  match.match_after === null || moment(match.match_after).isBefore(moment())
    ? match.client_status ?? match.couple_status
    : "Delayed";

export const prospectiveMatchDeadline = (
  resolved_at: string | null,
  delay_match_email: boolean,
) => {
  if (delay_match_email) {
    return "Email Delayed";
  }
  if (resolved_at === null) {
    return "";
  }
  const resolvedAt = moment(resolved_at).add({ hours: 3 });
  return `${resolvedAt.diff(moment(), "hours")}h`;
};

export default function MatchStatus(match: BasicMatch | ExtendedMatch) {
  let backgroundColor = "";
  let color: string | undefined;
  let clientStatus: string = parseClientStatus(match);
  switch (clientStatus) {
    case "In Queue":
      backgroundColor = colors.$greyBorder;
      break;
    case "Delayed":
      backgroundColor = colors.$night;
      color = "white";
      break;
    case "Referred Out":
    case "Rematched":
      backgroundColor = colors.$greyBorder;
      break;

    case "Did Not Convert":
      backgroundColor = colors.$red;
      break;
    case "Pending Match":
      backgroundColor = colors.$yellow;
      break;
    case "Prospective Match":
      backgroundColor = colors.$yellow;
      clientStatus = `${clientStatus} (${prospectiveMatchDeadline(
        match.resolved_at,
        match.delay_match_email,
      )})`;
      break;
    case "Scheduled":
    case "Active":
      backgroundColor = colors.$green;
      break;
  }

  return (
    <div
      style={{
        marginRight: "5px",
        textAlign: "center",
        padding: "5px 8px",
        minWidth: "130px",
        width: "100%",
        backgroundColor,
        color,
      }}
    >
      {clientStatus}
    </div>
  );
}
