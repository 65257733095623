import React from "react";
import { BasicMatch, ClientExtended, CouplesMatch } from "../../../api/types";

import {
  AlertFilled,
  ClockCircleFilled,
  RetweetOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { State } from "react-stateface";
import { Badge, Tag, Tooltip } from "antd";
import * as colors from "../../../assets/colors";
import { Flex, Row } from "../../_layout/Flex";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

const ServiceStateIconContainer = styledStitches("div", {
  $$stateColor: colors.$text,

  fontSize: "8pt",
  display: "flex",
  color: colors.$text,
  margin: "0 8px",
  [`& .react-stateface-container`]: {
    display: "inline-block",
    width: "12px",
    marginRight: "4px",
  },
  "& svg": {
    verticalAlign: "middle",
    fill: "$$stateColor",
  },
  variants: {
    state: {
      CA: {
        // go bears
        $$stateColor: "#FDB515",
      },
      WA: {
        $$stateColor: "#008467",
      },
    },
  },
  defaultVariants: {
    state: "none",
  },
});

export const ServiceStateIcon: React.FC<{ state: string }> = (props) => {
  const { state } = props;
  // allowlist of service states
  if (!["WA", "CA", "FL", "OH"].includes(state)) {
    return null;
  }

  return (
    // @ts-ignore
    <ServiceStateIconContainer state={state} key={state}>
      <State>{state}</State>
      <span>{state}</span>
    </ServiceStateIconContainer>
  );
};

const PayerTagContainer = styledStitches("div", {
  fontSize: "8pt",
  verticalAlign: "middle",
  marginRight: "4px",
});

export const PayerTag = ({ payer }: { payer: string }) => {
  if (!payer) {
    return null;
  }

  const payerString = payer.replace(/[^A-Z]+/g, ""); // in absense of a real 'code name', just use the capital letters from the display_name
  return (
    <Tooltip title={payer} key={payer}>
      <PayerTagContainer>{payerString}</PayerTagContainer>
    </Tooltip>
  );
};

export const StaticMatchIcons = ({
  basicMatch,
}: {
  basicMatch: BasicMatch | CouplesMatch;
}) => {
  const primaryClient: ClientExtended =
    basicMatch.client || basicMatch.couple?.clientA;
  const payers = primaryClient.payers?.map((p) => p.payer.display_name);
  return (
    <div
      style={{
        flex: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "center",
      }}
    >
      <Flex>
        {primaryClient.service_state && (
          <ServiceStateIcon state={primaryClient.service_state} />
        )}
      </Flex>
      <Row>
        {payers.map((payer) => (
          <PayerTag payer={payer} />
        ))}
      </Row>
    </div>
  );
};

export const MatchIcons = ({
  basicMatch,
}: {
  basicMatch: BasicMatch | CouplesMatch;
}) => {
  const isCouplesMatch = "couple" in basicMatch && basicMatch.couple !== null;

  const primaryClient = basicMatch.client || basicMatch.couple?.clientA;

  return (
    <Row layout="start center" style={{ width: "60px" }}>
      <Flex>
        {basicMatch.priority === "high_acuity" && (
          <Tooltip placement="top" title={"High Acuity Client"}>
            <AlertFilled style={{ color: colors.$red }} />
          </Tooltip>
        )}
        {basicMatch.priority === "high_priority" && (
          <Tooltip placement="top" title={"High Priority Client"}>
            <ClockCircleFilled style={{ color: colors.$red }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.returning_to_same_clinician && (
          <Tooltip placement="top" title={"Returning to Same Clinician"}>
            <UsergroupAddOutlined style={{ color: colors.$red }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.is_rematch && (
          <Tooltip placement="top" title={"Rematch"}>
            <RetweetOutlined style={{ color: colors.$green }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.former_client && (
          <Tooltip placement="top" title={"Former Client"}>
            <UserOutlined style={{ color: colors.$night }} />
          </Tooltip>
        )}
      </Flex>
    </Row>
  );
};
