import { Timezones } from "@/app/_helpers/time.constants";
import { Timezone } from "@/app/_shared/TimezoneContext";
import { DateTime } from "luxon";
import {
  ClientAppointmentStatusCountMap,
  ClientCadence,
  ClientStatus,
} from "../app/panel-management/types";
import { TagWeight, ClinicianTagWeight } from "./constants";

export type DivProps = React.HTMLAttributes<HTMLDivElement>;

export type BaseModel<T> = {
  id: number;
  created_at: string;
  modified_at: string;
  deleted_at: string | null;
} & T;

export type Day = "Mo" | "Tu" | "We" | "Th" | "Fr" | "Sa" | "Su";
export type Time =
  | "07:00:00"
  | "08:00:00"
  | "09:00:00"
  | "10:00:00"
  | "11:00:00"
  | "12:00:00"
  | "13:00:00"
  | "14:00:00"
  | "15:00:00"
  | "16:00:00"
  | "17:00:00"
  | "18:00:00"
  | "19:00:00"
  | "20:00:00";

export type Recurrence = "weekly" | "biweekly";
export interface Clinician {
  id: number;
  email: string;
  clinic_ids: number[];
  first_name: string;
  last_name: string;
  degree: string;
  director_of_id: number;
  people_manager_id: number | null;
  dob: string;
  user_id: number;
  enable_ot_matches: boolean;
  is_kaiser_norcal_approved: boolean;
  is_medicaid_approved: boolean;
  consult_cap: number;
  is_match_to_timeslot: boolean;
  is_remote: boolean;
  is_wheel: boolean;
  is_network: boolean;
  kp_matching_start_date: string;
  service_states: string[];
  current_payer_service_records: unknown[];
  primary_timezone?: string;
  active_licensing_records: {
    currently_taking_triage_consults: boolean;
  }[];
  ramp_start_date?: string;
  is_manager: boolean;
  configuration: ClinicianConfiguration;
}

export type Config = {
  healthie_care_team_sync: boolean | undefined;
  healthie_client_address_broadcast: boolean | undefined;
  healthie_clinician_incremental_sync_enabled: boolean | undefined;
  healthie_emergency_contact_broadcast: boolean | undefined;
  healthie_patient_broadcast_enabled: boolean | undefined;
  healthie_patient_incremental_sync_enabled: boolean | undefined;
  healthie_ui_enabled: boolean | undefined;
  roofdog_enabled: boolean | undefined;
  mhqol_enabled: boolean | undefined;
};

export type UserGroup =
  | "Clinical Leader"
  | "PanMan CAT"
  | "Consult Clinician"
  | "Couples Clinician"
  | "Matching Admin"
  | "MBC Program Beta User"
  | "MBC Data Alpha User"
  | "MDB Team View User"
  | "Availability Tool Slot Editor (1)"
  | "Availability Tool Match Request Editor (2)"
  | "Consult Timeslot Editor"
  | "Wheel CWA User"
  | "Flex Scheduling User"
  | "Resource Library - Beta User"
  | "Resource Library - Curator"
  | "Requeuer"
  | "Scheduling UI - Beta User"
  | "Scheduling UI - Writes Allowed"
  | "Quality UI - Beta User"
  | "Quality - Survey Beta"
  | "Group Therapy User"
  | "Group Therapy V2 User"
  | "Care Coordinator"
  | "Episodes of Care - Beta User"
  | "Availability Tool End Date User (4)"
  | "Dashboard V2 - Beta User"
  | "MHQOL Survey - Beta User"
  | "Care Alert - Beta User"
  | "Healthie - Beta User"
  | "Healthie Links - Enabled"
  | "Holistic Alerts - Beta User"
  | "Cogsworth Alpha"
  | "Cogsworth Beta"
  | "Ops Edit"
  | "2024-07: Training Hours TimeOff User"
  | "Consult Consent"
  | "2024-09: Tags V3 User";

export interface ClinicianHold {
  id: number;
  created_by: number;
  clinician: number;
  note: string;
  reason: string;
  start_at: string;
  end_at: string;
  created_at: string;
  deleted_at: string;
}

/**
 * Utilization / Billable Time Types
 */
export interface BillableHoursWeek {
  // metadata
  week: string;
  isoweek: number;
  isoyear: number;
  clinician: string;
  clinician_id: number;

  // discrete values
  therapy_attended: number;
  therapy_scheduled: number;
  therapy_noshows: number;

  consult_allocation: number;
  consults_attended: number;
  consults_scheduled: number;
  consult_noshows: number;

  workweek_hours: number;
  hours_without_pto: number;
  pto_hours: number;

  working_hours: number;
  target_client_hours: number;

  couples_attended: number;
  couples_scheduled: number;
  couples_noshow: number;

  // percentage utilization
  hours_utilization: number;
  billable_time_utilization: number;
  attended_therapy_billable_time: number;
  scheduled_therapy_billable_time: number;
  no_show_therapy_billable_time: number;
  therapy_billable_time: number;
  attended_consult_billable_time: number;
  scheduled_consult_billable_time: number;
  no_show_consult_billable_time: number;
  consult_billable_time: number;
  attended_couples_billable_time: number;
  scheduled_couples_billable_time: number;
  no_show_couples_billable_time: number;
  couples_billable_time: number;

  allocated_consult_billable_time: number;

  // deprecated
  group_billable_time: number;
  training_billable_time: number;
  transition_billable_time: number;

  training_hours: number;
  transition_hours: number;
}

export interface ClinicianResponse extends Clinician {
  utilization: ClinicianUtilization;
}

export interface ClinicianUtilizationWeek {
  attended_appointments: number;
  future_appointments: number;
  pending_matches: number;
  utilization_target: number;
  utilization: number;
  therapy_slots: number;
}

export interface ClinicianUtilization {
  clinician_id: number;
  errors: string[];
  new_clients_this_week: number[];
  target: number;
  utilization: number;
  weeks: { [startDate: string]: ClinicianUtilizationWeek };
  use_availability_tool: boolean;
}

export interface ManualCapacity {
  auto_capacity: number;
  block_biweekly: boolean;
  capacity: number;
  clinician: number | Clinician;
  id?: number | null;
  is_expired: boolean;
  is_manual: boolean;
  model_version: string | null;
  note?: string | null;
  _date_start?: string;
}

export interface ClinicianSlot {
  clinician_id: number;
  day_of_week: Day;
  note: string | null;
  recurrence: Recurrence;
  start_date: string;
  start_time: Time;
  subregion_id: number;
  work_from_home?: boolean;
  modality?: "hybrid" | "remote" | null;
  timezone: Timezone;
}

export interface ClinicianSlotAvailability {
  clinician_id: number;
  open_slots: ClinicianSlot[];
}

export interface ClinicianMatch {
  id: number;
  clinicianId: number;
  clientEmail: string;
}

export interface ClinicianMatchResponse {
  id: number;
  client: string;
  clinician: number;
}

export interface ClinicianWithSchedule extends Clinician {
  schedule: ScheduleItem[];
}

export interface RampWeek {
  target: number;
  num_week: number;
}

type Classification = {
  id: number;
  employment_type: string;
  schedule_type: string;
  service_lines: string[];
};

export type ClinicianConfiguration = {
  id: number;
  classification: Classification;
  default_target_client_hours: number;
  ramp_schedule: null | { [key: string]: RampWeek };
  max_low_demand_hours: number;
  utilization_expectation: string;
  valid_from: string;
  valid_until: null | string;
  clinician: number;
};

export interface ClinicianWithConfiguration extends ClinicianWithSchedule {
  configuration: ClinicianConfiguration;
}

export interface ScheduleItem {
  clinic_id: number;
  clinic_name: string;
  clinician_id: number;
  day_of_week: string;
  end_date: string;
  end_time: string;
  id: number;
  is_clinical_time: boolean;
  room_number: string;
  start_time: string;
  start_date: string;
  work_from_home: boolean;
}

export interface ScheduleDraftItem extends ScheduleItem {
  state: "pending" | "approved" | "invalidated";
  intended_clinical_hours: number;
  clinic?: number;
}

export type WorkFromHomeDays = {
  Monday?: boolean;
  Tuesday?: boolean;
  Wednesday?: boolean;
  Thursday?: boolean;
  Friday?: boolean;
};

export interface NewClinicianHold {
  clinician: number;
  note: string;
  reason: string;
  start_at: string;
  end_at: string;
}

export interface TeamResponse {
  is_superuser: boolean;
  is_director: boolean;
  clinicians: ClinicianWithConfiguration[];
}

export interface Clinic {
  id: number;
  display_name: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: number;
  subregion: number;
  is_remote: boolean;
}

export interface Subregion {
  id: number;
  display_name: string;
  shown_in_matchmaker: boolean;
}

export interface ClinicianWithTags extends Clinician {
  tags: MatchTag[];
}

interface FitBase {
  match: number;
  clinician: number;
  score: number;
}

export type Fit = BaseModel<FitBase>;
export type FitWithClinician = BaseModel<{
  match: number;
  clinician: ClinicianWithTags;
  score: number;
}>;

export interface FitSuggestion {
  score: number;
  clinician: ClinicianWithTags;
}

export interface FitSuggestionResponse {
  maxScore: number;
  suggestions: FitSuggestion[];
  invalid_fits: object;
}

export type MatchStatus = "queued" | "matched" | "referred" | "rematched";
export type MatchClientStatus =
  | "Active"
  | "Delayed"
  | "Did Not Convert"
  | "Error Getting Status"
  | "In Queue"
  | "Pending Match"
  | "Prospective Match"
  | "Referred Out"
  | "Rematched"
  | "Scheduled";

export interface BasicConsult {
  id: string;
  start_time: string;
  clinician: number;
}

export type MatchPriority = "high_acuity" | "high_priority" | null;

export type HybridPreference = "in_person_only" | "teletherapy_ok" | null;
export type DeliveryPreference = "hybrid" | "open_to_either" | "remote";

export interface CCVRData {
  [month: string]: {
    month: string;
    matches: number;
    percentage_converted: number;
    four_week_percentage_converted: number;
  };
}
export interface BasicMatch {
  assignee: number;
  assignee_id: number;
  cadence_pref_strength: "strong" | "open";
  client_appts_attended: number;
  client_status: MatchClientStatus;
  client: ClientExtended;
  clinician_approves_ot: boolean;
  conflict_of_interest: string;
  consult?: BasicConsult;
  couple?: CoupleForMatch;
  couple_status?: MatchClientStatus;
  created_at: string;
  created_by_clinician: number | null;
  created_by: number;
  delay_match_email: boolean;
  deleted_at: null;
  delivery_preference: DeliveryPreference;
  exclude_from_aqm: boolean;
  former_client: boolean;
  hybrid_preference: HybridPreference;
  id: number;
  is_biweekly_fit: boolean;
  is_rematch: boolean;
  match_after: string | null;
  match_email_sent: string;
  match_note: string;
  max_score: number;
  methodology_version: 1 | 2 | 3;
  modified_at: string;
  priority: MatchPriority;
  queue_note: string;
  queue_rank?: number;
  is_medicare: boolean;
  is_medical: boolean;
  referral_note: string;
  rematch_note: string;
  rematch: number | null;
  requeue_reason: string | null;
  resolved_at: string;
  returning_to_same_clinician: boolean;
  selected_fit: number | null;
  service_type: ServiceType;
  status: MatchStatus;
}

export type PendingMatch = (BasicMatch | CouplesMatch) & {
  selected_fit: Fit;
};

export type ResolvedMatch = PendingMatch & {
  conversion_event: Event | null;
};

export interface ExtendedMatch {
  assignee: number;
  cadence_pref_strength: "strong" | "open";
  client_appts_attended: number;
  client_status: MatchClientStatus;
  client: ClientExtended;
  clinic_preferences: ClinicPreference[];
  clinician_approves_ot: boolean;
  conflict_of_interest: string;
  consult_tags: MatchTag[];
  consult: Consult | string | null;
  couple?: CoupleForMatch;
  couple_status?: MatchClientStatus;
  created_at: string;
  created_by_clinician: number | null;
  delay_match_email: boolean;
  delivery_preference: DeliveryPreference;
  former_client: boolean;
  hybrid_preference: HybridPreference;
  id: number;
  is_biweekly_fit: boolean;
  is_medicare: boolean;
  is_medical: boolean;
  is_rematch: boolean;
  match_after: string | null;
  match_email_sent: string;
  match_note: string;
  max_score: number;
  methodology_version: 1 | 2 | 3;
  priority: MatchPriority;
  queue_note: string;
  referral_note: string;
  rematch_note: string;
  rematch: ExtendedMatch | null;
  resolved_at: string | null;
  returning_to_same_clinician: boolean;
  selected_fit: FitWithClinician | null;
  service_type: ServiceType;
  status: string;
  tags: MatchTag[];
}

export interface MatchData {
  id?: number;
  consultId: string | null;
  clientEmail: string | null;
  conflictOfInterest?: string;
  consultTagWeightMap: TagWeightMap;
  excludeFromAQM: boolean;
  matchTagWeightMap: TagWeightMap;
  starredTagIds: number[];
  selectedClinicianId: number | null;
  consultClinicIds?: number[];
  matchClinicIds?: number[];
  queueNote: string;
  matchNote: string;
  referralNote: string;
  isReferral: boolean;
  rematchNote: string;
  isRematch: boolean;
  rematchId: number | null;
  matchAfter: string | null;
  excludedClinicianIds: number[];
  matchSlotPreferences?: MatchSlotPreference[];
  profileSlotPreferences?: MatchSlotPreference[];
  conflictsOfInterest: {
    id: number | string;
    type: "clinician" | "client" | "hq_member";
  }[];
  isBiweeklyFit: boolean;
  cadencePrefStrength: "strong" | "open";
  matchAgeRange: MatchAgeRange | null;
  deliveryPreference: DeliveryPreference;
  clinicianApprovesOt: boolean;
  hybridPreference: HybridPreference;
  priority: MatchPriority;
  serviceType: ServiceType;
  methodologyVersion: 1 | 2 | 3;
}

export interface MatchDraft {
  id?: number;
  consult_id: string | null;
  priority: MatchPriority;
  max_score: number;
  is_rematch: boolean;
  rematch_note: string;
  is_referral: boolean;
  referral_note: string;
  queue_note: string;
  match_note: string;
  rematch_id: number | null;
  conflict_of_interest: string;
  match_after: string | null;
  is_biweekly_fit?: boolean;
  cadence_pref_strength?: "strong" | "open";
  delivery_preference?: DeliveryPreference;
  clinician_approves_ot?: boolean;
  exclude_from_aqm: boolean;
  hybrid_preference: HybridPreference;
  returning_to_same_clinician: boolean;
}

export interface HqMember {
  id: string;
  email: string;
  full_name: string;
}

export interface APIConflictOfInterest {
  id: string;
  match_id: number;
  clinician_id: number;
  client_id: number;
  client: ClientExtended | null;
  hq_member_id: string;
  hq_member: HqMember;
}

export type ConflictOfInterest =
  | {
      id: number;
      type: "clinician";
    }
  | {
      id: number;
      type: "client";
      first_name: string;
      last_name: string;
      clinician_ids: number[];
    }
  | {
      id: string;
      type: "hq_member";
      full_name: string;
      email: string;
    };

export type MatchAgeRange = {
  minimum_age: number | null;
  maximum_age: number | null;
  filter_clinical_fits: boolean;
};

export interface MatchSlotPreference {
  id?: string;
  match?: number;
  subregion: number | null;
  day_of_week: Day;
  start_time: string;
  end_time: string;
  preference_origin: "client-profile" | "matchmaker";
  location_preference_level: "strong" | "open" | null;
  is_maybe_slot: boolean;
  is_general: boolean;
}

export interface ClinicPreference {
  id: number;
  clinic_id: number;
  match_id: number;
  preference_level: "match" | "consult";
}

export interface TagWeightMap {
  [tagId: number]: number;
}

export interface Session {
  id: number;
  start_time: string;
  end_time: string;
  clinic_id: number;
  clinician_id: number;
  procedure_id: number;
}

export interface PanelMeta {
  note_content: string;
  id?: string;
}

export interface ClinicianWeek {
  id: string;
  week_number: number;
  year: number;
  note_content: string;
  clinician: number;
  sessions: number[] | PanelSession[];
}

export interface APIClinicianWeek extends ClinicianWeek {
  sessions: PanelSession[];
}

export interface PanelClinicianWeek extends ClinicianWeek {
  sessions: number[];
  consults?: number[];
}

export interface PanelSession extends Session {
  procedure: number;
  meta: PanelMeta;
  appointment: PanelAppointment[];
  event_id: string;
  clinician_week: string;
  event: Event;
}

export interface AppointmentClient {
  id: number;
  first_name: string;
  last_name: string;
  preferred_name?: string;
  email?: string;
  phone_number?: string;
  welkin_patient?: string;
  welkin8_id?: string;
  healthie_id?: number;
  hybrid_preference: DeliveryPreference;
}
export interface PanelAppointment extends Appointment {
  client: AppointmentClient;
}

export interface PanelClient extends AppointmentClient {
  meta?: PanelClientMeta;
}

export interface PanelClientMeta {
  id: string;
  note_content: string;
  cadence: string;
  cadence_override: ClientCadence;
  client: AppointmentClient;
  status: {
    state: ClientStatus;
    future_appts: number;
    attended_appts: number;
    canceled_appts: number;
    noshow_appts: number;
  };
  sessionCountInRange: ClientAppointmentStatusCountMap;
  mbcAdherenceScores: {
    preSession: number;
  };
}

export interface ClientPayerServiceRecord {
  client: number;
  expected_coverage_termination_date: null | string;
  coverage_termination_date: null | string;
  coverage_start_date: string;
  id: string;
  payer: Payer;
  service_type: "individual" | "couple";
}

export interface Payer {
  id: string;
  internal_name: string;
  display_name: string;
}

export interface Client {
  first_name: string;
  last_name: string;
  id: number;
  appointments?: Appointment[];
  email: string;
  hybrid_preference: "hybrid" | "remote";
  initials: string;
  is_kp_referral: boolean;
  is_medical: boolean;
  is_medicare: boolean;
  service_state: string;
  payers: ClientPayerServiceRecord[];
  primary_timezone: Timezone;
}

export interface Couple {
  id: number;
  initials: string;
  appointments?: Appointment[];
}

export interface CoupleExtended extends Couple {
  client_a: number;
  client_b: number;
  client_a_first_name: string;
  client_b_first_name: string;
  client_a_last_name: string;
  client_b_last_name: string;
  welkin_patient_id?: string;
  is_kp_referral: boolean;
}

export interface ClientExtended extends Client {
  first_name: string;
  last_name: string;
  phone_number: string;
  welkin_patient_id?: string;
  welkin8_id?: string;
  healthie_id?: number;
  dob: string;
  latest_referral_for_client: {
    payer_name: string;
    diagnosis: string;
    reason_for_care: string;
    referring_provider: string;
    provider_state: string;
  };
}

export type AppointmentStatus = "attended" | "canceled" | "noshow" | null;
export interface Appointment {
  time_scheduled: string;
  session_id: number;
  client_id: number;
  appointment_status: AppointmentStatus;
}

export interface Tag {
  id: number;
  bucket: string;
  category: string;
  name: string;
  shown_in_matchtool: boolean;
  used_for_individual: boolean;
  used_for_couples: boolean;
  status: "deprecated" | null | undefined;
  version: 1 | 2 | 3;
}
export interface MatchTag extends Tag {
  weight: TagWeight;
  starred: boolean;
}
export interface ClinicianMatchTag extends Tag {
  weight: ClinicianTagWeight;
  version: 1 | 2 | 3;
}

export interface CurrentUser {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  groups: UserGroup[];
  permissions: string[];
  clinician?: Clinician;
  isSuperUser: boolean;
  id: number;
}

export interface APICurrentUser {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  groups: Array<{ [name: string]: string }>;
  permissions: string[];
  clinician?: Clinician;
  is_superuser: boolean;
  id: number;
}

export interface BasicUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface APIClinicianTag {
  id: number;
  clinician_id: number;
  tag_id: number;
  weight: -1 | 1 | 2 | 4;
  tag: Tag;
  version: 1 | 2 | 3;
  prefer_not_to_treat: boolean;
  metadata?: { is_specialty?: boolean } | null;
}

export interface ClinicianTagInput {
  clinician_id: number;
  tag_id: number;
  weight: -1 | 1 | 2 | 4;
  prefer_not_to_treat: boolean;
  metadata?: { is_specialty?: boolean } | null;
}

export interface FormQuestion {
  answer_text: string;
  answer_type: string;
  question_text: string;
  vendor: "survey_monkey";
  vendor_question_id: string;
}

export interface ConsultNPSForm {
  id: string;
  client_id: number;
  match_id: number;
  blob: FormQuestion[];
}

export interface ConsultNPSFormWithScores {
  nps: number | null;
  feedbackAvg: number | null;
  form: ConsultNPSForm;
}

export interface ImmutableModel {
  id: string;
  _deleted: boolean;
  _date_start: string;
  _immutable_version_id: string;
}

export type EventDelivery = "teletherapy" | "in_person";
export type EventServiceType = ServiceType;
export interface Event extends ImmutableModel {
  client: number | Client;
  couple: string | Couple;
  clinic: number | Clinic;
  clinician: number | Clinician;
  clinician_week_id: string;
  procedure_id: number;
  procedure?: Procedure;

  welkin_id: number;

  start_time: string;
  end_time: string;
  event_type: "procedure" | "block" | null;

  appointment_status: "attended" | "canceled" | "noshow" | null;

  slot_id: string;
  slot_week: string;
  source: "Welkin" | "Acuity" | "Slot";

  time_scheduled: string;
  time_canceled: string;
  cost: number;
  paid: boolean;
  certificate_code: string | null;

  amount_paid_online: number;
  legacy_superbilled: boolean;
  superbill_id: string;

  is_session: boolean;
  is_appointment: boolean;
  session_id: string;

  modified_at: string;
  created_at: string;
  deleted_at: string;

  gcal_unique_id?: string;

  delivery: EventDelivery;
  service_type: EventServiceType;
}

export interface Consult extends Event {
  event_type: "procedure";
  procedure_id: 2;
  client: Client;
  consult_note?: ConsultNote;
  matchmaker_completed?: boolean;
  consult_note_id?: string;
  consult_note_submitted?: boolean;
  shadowing_clinicians: number[];
}

export interface ConsultNote {
  id?: string;
  submitted_at?: Date;
  other_emotional_history_text?: string;
  prelim_dx_code_other_text?: string;
  goals_text?: string;
  prior_therapy_text?: string;
  other_text?: string;
  substanceuses?: Array<SubstanceUse>;
  presentingproblems?: Array<PresentingProblem>;
  functional_impairment?: FunctionalImpairment;
  consult_modality?: string;
  teletherapy_checklist_client_identity?: boolean;
  teletherapy_checklist_emergency_contact?: boolean;
  teletherapy_checklist_confidentiality_consent?: boolean;
  physical_location_from_client_chart?: boolean;
  physical_location_other?: string;
  self_injurious_behavior?: SelfInjuriousBehavior;
  cognitive_dementia_endorsed?: boolean;
  cognitive_dementia_additional_details?: string;
  endorsed_prior_ipv?: boolean;
  endorsed_current_ipv?: boolean;
  ipv_additional_details?: string;
  criterion_trauma_endorsed?: boolean;
  criterion_trauma_endorsed_additional_details?: string;
  demographics_info?: string;
  past_present_focus_preference?: string;
  past_present_focus_intensity?: string;
  mental_status?: MentalStatus;
  therapy_preferences?: TherapyPreferences;
  last_uploaded?: Date;
  event?: string;
  group_discussed?: string;
  group_discussed_no_reason?: string;
  group_discussed_other_description?: string;
  group_clinically_appropriate?: string;
  group_clinically_appropriate_no_reason?: string;
  group_clinically_appropriate_other_description?: string;
  group_two_chairs_appropriate?: string;
  group_two_chairs_appropriate_no_reason?: string;
  group_two_chairs_appropriate_other_description?: string;
  group_two_chairs_appropriate_yes_intention?: string;
  group_two_chairs_appropriate_yes_intention_no_reason?: string;
  group_two_chairs_appropriate_yes_intention_other_description?: string;
  referred_out_text?: string;
  referred_out_reason?: string;
  referred_out_other_description?: string;
  referred_out_required_care?: string;
  referred_out_required_care_other_description?: string;
  referred_out_continuing_care?: string;
  referred_out_continuing_care_other_description?: string;
  referred_out_urgent?: string;
  referred_out_client_comms?: string;
  referred_out_specificity?: string;
  referred_out_gender_preference?: string;
  referred_out_additional_context?: string;
}

export interface SubstanceUse {
  id: string;
  additional_details: string;
  consult_note: string;
  frequency: string;
  intensity: string;
  substance: string;
  order: number;
}

export interface PresentingProblem {
  id: string;
  additional_details: string;
  consult_note: string;
  problem: string;
  severity: string;
  duration: string;
  order: number;
}

export interface FunctionalImpairment {
  social_functioning: string;
  occupation_functioning: string;
  dailylife_functioning: string;
}

export interface SelfInjuriousBehavior {
  denied: boolean;
  endorsed_current_active_si: boolean;
  endorsed_current_active_hi: boolean;
  endorsed_current_sib: boolean;
  endorsed_current_passive_si: boolean;
  endorsed_current_passive_hi: boolean;
  endorsed_prior_si_attempt: boolean;
  endorsed_prior_si: boolean;
  endorsed_prior_hi: boolean;
  endorsed_prior_sib: boolean;
  endorsed_prior_hospitalizations: boolean;
  safety_plan_details: string;
}

export interface MentalStatus {
  agitated_little: boolean;
  agitated_lot: boolean;
  animated_little: boolean;
  animated_lot: boolean;
  ashamed_little: boolean;
  ashamed_lot: boolean;
  constricted_little: boolean;
  constricted_lot: boolean;
  determined_little: boolean;
  determined_lot: boolean;
  euphoric_little: boolean;
  euphoric_lot: boolean;
  inspired_little: boolean;
  inspired_lot: boolean;
  irritable_little: boolean;
  irritable_lot: boolean;
  nervous_little: boolean;
  nervous_lot: boolean;
  sad_little: boolean;
  sad_lot: boolean;
  tearful_little: boolean;
  tearful_lot: boolean;
  linear_and_goal_directed_little: boolean;
  linear_and_goal_directed_lot: boolean;
  disorganized_little: boolean;
  disorganized_lot: boolean;
  racing_little: boolean;
  racing_lot: boolean;
  apathetic_little: boolean;
  apathetic_lot: boolean;
  defensive_little: boolean;
  defensive_lot: boolean;
  engaged_little: boolean;
  engaged_lot: boolean;
  guarded_little: boolean;
  guarded_lot: boolean;
  hostile_little: boolean;
  hostile_lot: boolean;
  open_little: boolean;
  open_lot: boolean;
  suspicious_little: boolean;
  suspicious_lot: boolean;
}

export interface TherapyPreferences {
  structure_preference: string;
  structure_intensity: string;
  directiveness_preference: string;
  directiveness_intensity: string;
  expressiveness_preference: string;
  expressiveness_intensity: string;
  homework_preference: string;
  homework_intensity: string;
  demo_category_one: string;
  demo_intensity_one: string;
  demo_category_two: string;
  demo_intensity_two: string;
  demo_category_three: string;
  demo_intensity_three: string;
  additional_details_demo_one: string;
  additional_details_demo_two: string;
  additional_details_demo_three: string;
}

export interface ConsultExtended extends Consult {
  client: ClientExtended;
  consult_note: ConsultNote;
}

export interface IdMap<T> {
  [id: string]: T;
}

/**
 *  `[isoweekString: string]: <T>;`
 *
 *  where isoweekString in format `[IYYY-WIW]`
 *
 *  example: 2020-W01 for 12-30-2019
 */
export type ISOWeekMap<T> = IdMap<T>;
export interface IntIdMap<T> {
  [id: number]: T;
}

export type DayTime = {
  day: Day;
  time: Time;
};

export type SlotPref = {
  day: Day;
  time: Time;
  pref_level: "preferred" | "maybe";
};

export interface ShoppingCartItem {
  fit: Fit;
  match: BasicMatch;
  slot?: ClinicianSlot;
  is_manual_match: boolean;
}

export interface MatchCountRowItem {
  matchedClinician: number;
  matchesSuggested: number;
  matchesRequested: number;
}

export interface MBCClientAdherence {
  // clinician_email: string;
  client: number;
  // client_name: string;
  num_sessions: number;
  pre_session_adherence: number;
}

export interface SchedulingLink {
  clinician_id: number;
  link: string;
}

export interface EventMbcStatus {
  id?: string;
  event: string;
  client: number;
  clinician: number;
  couple: string;
  assessment_type: "pre-session";
  assessment_send_started: string | null;
  assessment_email_sent_at: string | null;
  assessment_email: string | null;
  assessment_response: string | null;
  assessment_responded_at: string | null;
  send_attempts: number;
  assessment_send_failure: string | null;
  assessment_url: string | null;
}

export interface Exclusions {
  match_ids: number[];
  fit_ids: number[];
}

export interface MatchSuggestion {
  aqm_score: number;
  clinician_id: number;
  day_of_week: Day;
  fit_id: number;
  fit_score: number;
  match_id: number;
  note: string | null;
  slot_id: string;
  recurrence: Recurrence;
  start_date: string;
  start_time: Time;
  subregion_id: number;
  work_from_home: boolean;
  modality: "hybrid" | "remote" | null;
  timezone: Timezone;
}

export interface SlotlessMatch {
  clinician_id: number;
  fit_id: number;
  fit_score: number;
  match_id: number;
  note: string | null;
  recurrence: Recurrence;
}

export interface Inclusion {
  match: BasicMatch | CouplesMatch;
  slot: MatchSuggestion | SlotlessMatch;
}

export interface AQMRestrictions {
  exclusions?: Exclusions;
  inclusions?: Inclusion[];
}

export const AQMAdditiveTuningParams = [
  "acuity_factor",
  "baseline_factor",
  "client_matchability_factor",
  "clinician_cap_factor",
  "clinician_tenure_factor",
  "fit_score_factor",
  "kp_mismatch_factor",
  "kp_referral_factor",
  "location_preference_factor",
  "long_term_clinician_matchability_factor",
  "maybe_slot_factor",
  "priority_factor",
  "queue_time_factor",
  "queue_time_squared_factor",
  "rematch_factor",
  "same_clinician_factor",
  "session_clinician_matchability_factor",
  "starred_benefits_factor",
  "starred_needs_factor",
] as const;

export const AQMMultiplicativeTuningParams = [
  "biweekly_cadence_match_factor",
  "biweekly_only_to_weekly_slot_factor",
  "biweekly_preference_to_weekly_slot_factor",
  "either_to_in_person_factor",
  "either_to_tele_factor",
  "in_person_to_in_person_factor",
  "start_tele_to_in_person_factor",
  "start_tele_to_tele_factor",
  "tele_to_in_person_factor",
  "tele_to_tele_factor",
  "weekly_cadence_match_factor",
  "weekly_preference_to_biweekly_slot_factor",
  "wheel_clinician_factor",
] as const;

export const AQMThresholdTuningParams = [
  "biweekly_preference_to_weekly_threshold_factor",
  "cadence_pref_override_threshold_factor",
  "weekly_preference_to_biweekly_threshold_factor",
] as const;

export const AQMFitFactorParams = [
  "boolean_fit_aetna_to_wheel_factor",
  "boolean_fit_dtc_to_wheel_factor",
] as const;

export const AQMOtherTuningParams = [
  "network_target_down_adjustment_california_factor",
  "network_target_down_adjustment_washington_factor",
  "network_target_down_adjustment_florida_factor",
] as const;

export const AQMTuningParams = [
  ...AQMAdditiveTuningParams,
  ...AQMMultiplicativeTuningParams,
  ...AQMThresholdTuningParams,
  ...AQMFitFactorParams,
  ...AQMOtherTuningParams,
] as const;

// export type AQMAddativeTuningParamsBaseTuple = typeof AQMAdditiveTuningParams;
export type AQMTuningParamsBaseTuple = typeof AQMTuningParams[number];

export type AQMTuningParamsBase<T> = {
  [k in AQMTuningParamsBaseTuple]: T;
};

export type AQMTuningParams = AQMTuningParamsBase<unknown>;

export interface AQMScoreBreakdown {
  score: number;
  components: AQMTuningParamsBase<number>;
  modality: "hybrid" | "remote" | undefined | null;
  work_from_home: boolean;
}

export interface AQMScore {
  [slot: string]: AQMScoreBreakdown;
}

export interface AQMScores {
  [match: string]: AQMScore;
}

export interface AQMResponse {
  errors: string[];
  scores: AQMScores;
  suggestions: MatchSuggestion[];
  id: number;
  queue_condition_id: string;
}

export interface NotificationFields {
  client_id?: number;
  clinician_id?: number;
  clinician_type?: "consult" | "treatment" | "clinical_director";
  couple_id?: string;
  event_id?: string;
  match_id?: number;
  match_session_date?: string;
  user_id?: number;
}

export interface ConsultNoteQuestion {
  questionNumber: number;
  title: string;
  subtitle?: string;
  model: string;
  slug: string;
  isRequired?: boolean;
  defaultValue?: string;
  instructionText?: string;
  hideNumber?: boolean;
}
export interface ConsultAndNoteNoteData {
  consult: ConsultExtended;
  consultNoteData: any;
  readOnly?: boolean;
}

export type ServiceType = "individual" | "couples";

export type Procedure =
  | "consult"
  | "therapy"
  | "teletherapy"
  | "couples_therapy"
  | "couples_teletherapy";
export interface TagChoice {
  tag_id: number;
  tag_name: string;
}

export interface CouplesJointIntakeSurveyQuestion {
  id: string;
  add_text_box: boolean;
  answer_options: string[];
  is_optional: boolean;
  lower_extreme: string | null;
  question_text: string;
  question_type: string;
  slug: string;
  tag_choices: TagChoice[];
  upper_extreme: string | null;
}

export interface TagResponse {
  structured_response: string | string[] | number | number[];
  unstructured_response: string;
}

export interface CouplesJointIntakeSurveyQuestionResponse {
  id: string;
  created_at: string;
  modified_at: string;
  deleted_at: string;
  general_survey_question: string;
  general_survey_response: string;
  value: { [key: string]: string | string[] | TagResponse };
}
export interface JointIntakeFormResponse {
  form_questions: CouplesJointIntakeSurveyQuestion[];
  form_responses: CouplesJointIntakeSurveyQuestionResponse[];
}

export interface CoupleForMatch {
  chroniclerId: string;
  clientA: ClientExtended;
  clientB: ClientExtended;
  initials: string;
  is_kp_referral: boolean;
  is_medical?: boolean;
  is_medicare?: boolean;
  joint_intake_form_response?: JointIntakeFormResponse;
  welkinId: string;
}

export interface CouplesMatch {
  assignee: number;
  cadence_pref_strength: "strong" | "open";
  client_appts_attended: number;
  client_status: MatchClientStatus;
  couple_status: MatchClientStatus;
  client: any;
  clinician_approves_ot: boolean;
  conflict_of_interest: string;
  consult: BasicConsult;
  couple: CoupleForMatch;
  created_at: string;
  created_by_clinician: number | null;
  created_by: number;
  delay_match_email: boolean;
  deleted_at: null;
  delivery_preference: DeliveryPreference;
  exclude_from_aqm: boolean;
  former_client: boolean;
  hybrid_preference: HybridPreference;
  id: number;
  is_biweekly_fit: boolean;
  is_medical: boolean;
  is_medicare: boolean;
  is_rematch: boolean;
  match_after: string | null;
  match_email_sent: string;
  match_note: string;
  max_score: number;
  methodology_version: 1 | 2;
  modified_at: string;
  priority: MatchPriority;
  queue_note: string;
  queue_rank?: number;
  referral_note: string;
  rematch_note: string;
  rematch: number | null;
  requeue_reason: string | null;
  resolved_at: string;
  returning_to_same_clinician: boolean;
  selected_fit: number | null;
  service_type: ServiceType;
  status: MatchStatus;
}

export interface CouplesMatchAvailability {
  availability: number;
  open_slots: ClinicianSlot[];
}

export interface MatchFit {
  dayOfWeek?: Day;
  fitId: number;
  matchId: number;
  matchNote: string;
  recurrence?: Recurrence;
  startDate?: string;
  startTime?: Time;
  timezone?: Timezones;
  resolved_modality: "hybrid" | "remote" | undefined | null;
  is_manual_match: boolean;
}

export type CsvDataItem = string[];
